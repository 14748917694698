<template>
  <b-container fluid="xxl">
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('sensor.tableTitle')" />
      <GoldflamTable
        :new-item="newSensorObject"
        :all-items="filteredSensors"
        :edit-function="editSensorFunction"
        :delete-function="deleteSensor"
        :disable-crud-functions="disableCrudFunctions"
        :table-fields="tableFields"
        stacked="lg"
      >
        <template v-slot:filters>
          <b-col lg="2" md="4" sm="5">
            <b-select id="userNameFilter" v-model="filter.user" :options="userSelectOpts">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-users') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
          <b-col lg="4" md="5" sm="12">
            <b-select id="userNameFilter" v-model="filter.sensorLocation" :options="sensorLocationSelectOpts">
              <template #first>
                <b-form-select-option :value="null">{{ $t('sensor.all-sensor-locations') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
        </template>
        <template v-slot:cell(operator)="row">
          <span v-if="row.item.operator.fullName">{{ row.item.operator.fullName }}</span>
          <span v-else>#{{ row.item.operator.id }}</span>
        </template>
      </GoldflamTable>

      <b-modal
        id="editModal"
        v-model="modalControl.edit.show"
        size="lg"
        centered
        :title="$t('sensor.editSensor')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveSensor(editSensor)"
        :ok-disabled="$v.$invalid"
        @hidden="resetEditModal"
      >
        <b-form>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editOperator"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.operator')"
            :state="validateFormInput('operator')"
          >
            <b-select
              id="editOperator"
              v-model="$v.editSensor.operator.$model"
              :options="userSelectOpts"
              :disabled="!isAdmin"
            />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editLocation"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.location')"
            :state="validateFormInput('sensor_location')"
          >
            <b-select
              id="editLocation"
              v-model="$v.editSensor.sensor_location.$model"
              :options="sensorLocationSelectOpts"
            />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editShowPublic"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.showPublic')"
            :state="validateFormInput('show_public')"
          >
            <b-form-checkbox
              id="editShowPublic"
              v-model="$v.editSensor.show_public.$model"
              name="showPublic-checkbox"
              :unchecked-value="false"
            />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editHardwarePlatform"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.hardwarePlatform')"
            :state="validateFormInput('hardware_platform')"
          >
            <b-form-input id="editHardwarePlatform" type="text" v-model="$v.editSensor.hardware_platform.$model" />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editOs"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.operatingSystem')"
            :state="validateFormInput('os')"
          >
            <b-form-input id="editOs" type="text" v-model="$v.editSensor.os.$model" />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editCameraType"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.cameraType')"
            :state="validateFormInput('camera_type')"
          >
            <b-form-input id="editCameraType" type="text" v-model="$v.editSensor.camera_type.$model" />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editWingbeatSensorType"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.wingbeatSensorType')"
            :state="validateFormInput('wingbeat_sensor_type')"
          >
            <b-form-input id="editWingbeatSensorType" type="text" v-model="$v.editSensor.wingbeat_sensor_type.$model" />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editBokehVersion"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('sensor.table.bokehVersion')"
            :state="validateFormInput('bokehVersion')"
          >
            <b-form-input id="editBokehVersion" type="text" v-model="$v.editSensor.bokehVersion.$model" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { cloneDeep as _cloneDeep } from 'lodash'

export default {
  name: 'ManageSensors',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { GoldflamTable },

  data() {
    return {
      objectModels: {
        sensor: {
          operator: null,
          sensor_location: '',
          show_public: false,
          hardware_platform: '',
          os: '',
          camera_type: '',
          wingbeat_sensor_type: '',
          bokehVersion: ''
        }
      },
      editSensor: {},
      allUsers: [],
      tableFields: [
        { key: 'id', label: this.$t('general.id'), sortable: true, formatter: 'formatId', width: '50px' },
        { key: 'operator', label: this.$t('sensor.table.operator'), sortable: true },
        {
          key: 'sensor_location',
          label: this.$t('sensor.table.location'),
          formatter: 'formatNameWithId',
          sortable: true
        },
        {
          key: 'show_public',
          label: this.$t('sensor.table.showPublic'),
          formatter: 'translateBoolean',
          sortable: true
        },
        { key: 'hardware_platform', label: this.$t('sensor.table.hardwarePlatform'), sortable: true },
        { key: 'os', label: this.$t('sensor.table.operatingSystem'), sortable: true },
        { key: 'camera_type', label: this.$t('sensor.table.cameraType'), sortable: true },
        { key: 'wingbeat_sensor_type', label: this.$t('sensor.table.wingbeatSensorType'), sortable: true },
        { key: 'bokehVersion', label: this.$t('sensor.table.bokehVersion'), sortable: true },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '80px' }
      ],
      userList: [],
      sensorList: [],
      sensorLocationList: [],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      modalControl: {
        edit: {
          show: false
        },
        delete: {
          show: false
        }
      },
      filter: {
        user: null,
        sensorLocation: null
      }
    }
  },
  validations: {
    editSensor: {
      operator: {
        required
      },
      sensor_location: {
        required
      },
      show_public: {
        required
      },
      os: {
        required
      },
      camera_type: {
        required
      },
      hardware_platform: {
        required
      },
      wingbeat_sensor_type: {
        required
      },
      bokehVersion: {
        required
      }
    }
  },
  created() {
    this.editSensor = _cloneDeep(this.objectModels.sensor)
  },
  mounted() {
    this.getAllSensors()
    this.getAllSensorLocations()
    this.getAllUsers()
  },
  computed: {
    currentUser() {
      return this.$store.getters.getCurrentUser
    },
    userSelectOpts() {
      let opts = []
      this.userList.forEach(u => {
        const displayName = u.fullName ? u.fullName : `#${u.id}`
        opts.push({ value: u, text: displayName })
      })
      return opts
    },
    sensorLocationSelectOpts() {
      let opts = []
      this.sensorLocationList.forEach(l => {
        opts.push({ value: l, text: '#' + l.id + ' - ' + l.name })
      })
      return opts
    },
    filteredSensors() {
      let sensors = this.sensorList
      if (this.filter.user !== null && this.filter.user.id !== null && this.filter.user.id !== '') {
        sensors = sensors.filter(s => {
          return s.operator.id === this.filter.user.id
        })
      }
      if (
        this.filter.sensorLocation !== null &&
        this.filter.sensorLocation.id !== null &&
        this.filter.sensorLocation.id !== ''
      ) {
        sensors = sensors.filter(s => {
          return s.sensor_location.id === this.filter.sensorLocation.id
        })
      }
      return sensors
    },
    newSensorObject() {
      return _cloneDeep(this.objectModels.sensor)
    }
  },
  methods: {
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editSensor[prop]
      return $dirty ? !$invalid : null
    },
    getAllUsers() {
      let self = this
      this.getRequest(
        '/users/_views/simple',
        new RequestConfig().onSuccess(res => {
          self.userList = res.data
        })
      )
    },
    getAllSensors() {
      let self = this
      this.getRequest(
        '/sensors',
        new RequestConfig().onSuccess(res => {
          self.sensorList = res.data
        })
      )
    },
    getAllSensorLocations() {
      let self = this
      this.getRequest(
        '/sensor_locations',
        new RequestConfig().onSuccess(res => {
          self.sensorLocationList = res.data
        })
      )
    },
    saveSensor(sensor) {
      let url = '/sensors'
      let method = 'post'
      if (sensor.id) {
        url += `/${sensor.id}`
        method = 'put'
      }
      let self = this
      this.apiRequest(
        method,
        url,
        sensor,
        new RequestConfig().onSuccess(() => {
          if (sensor.id) {
            self.displaySuccess(self.$t('sensor.sensor-edit-success-msg'))
          } else {
            self.displaySuccess(self.$t('sensor.sensor-success-msg'))
          }
          this.getAllSensors()
        })
      )
    },
    editSensorFunction(sensor) {
      this.editSensor = _cloneDeep(sensor)
      // preset the operator to the current user for new sensors created by non-admin roles
      if (sensor.operator == null && !this.isAdmin) {
        this.editSensor.operator = {
          id: this.currentUser.id,
          fullName: this.currentUser.fullName,
          username: this.currentUser.username
        }
      }
      this.modalControl.edit.show = true
    },
    deleteSensor(sensor) {
      let self = this
      const id = sensor.id
      this.deleteRequest(
        `/sensors/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(this.$t('sensor.sensor-delete-success-msg'))
          this.getAllSensors()
        })
      )
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.editSensor = _cloneDeep(this.objectModels.sensor)
      this.$v.$reset()
    },
    disableCrudFunctions(sensor) {
      return !this.isAdmin && sensor.operator.id !== this.currentUser.id
    }
  }
}
</script>
