<template>
  <div class="text-center my-2">
    <b-spinner class="align-middle mr-2" variant="secondary" />
    <strong class="text-secondary">{{ $t('components.loadingSpinner.loading') }}</strong>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner'
}
</script>
