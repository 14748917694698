<template>
  <div>
    <b-container fluid id="headContainer" class="p-0 mb-3">
      <b-row align-h="between" align-v="end" class="flex-sm-wrap">
        <b-col>
          <b-button @click="editItem(newItem)" variant="primary" class="mr-3">{{
            $t('general.table.createNewButton')
          }}</b-button>
          <slot name="extra-action-button" />
        </b-col>
        <slot name="filters" />
      </b-row>
    </b-container>

    <b-table
      v-show="allItems"
      id="goldflamTable"
      small
      striped
      bordered
      head-variant="light"
      hover="hover"
      show-empty
      :empty-text="$t('general.table.empty-text')"
      :empty-filtered-text="$t('general.table.empty-text')"
      :fields="tableFields"
      :items="allItems"
      :busy="busy"
      :current-page="tableControls.currentPage"
      :per-page="tableControls.perPage"
      @filtered="onFiltered"
      :sort-by="sortBy"
      :stacked="stacked"
    >
      <template #table-busy>
        <LoadingSpinner />
      </template>
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
      </template>
      <template v-slot:cell(crudActions)="row">
        <b-button
          @click="editFunction(row.item)"
          size="xs"
          variant="outline-secondary"
          class="mr-2"
          :disabled="crudFunctionsDisabled(row.item)"
        >
          <b-icon icon="pencil" aria-hidden="true" />
        </b-button>
        <b-button
          v-if="mergeFunction"
          @click="mergeFunction(row.item, newItem)"
          size="xs"
          variant="outline-secondary"
          class="mr-2"
          :disabled="crudFunctionsDisabled(row.item)"
        >
          <b-icon icon="intersect" aria-hidden="true" />
        </b-button>
        <b-button
          @click="openDeleteModal(row.item)"
          size="xs"
          variant="outline-danger"
          :disabled="crudFunctionsDisabled(row.item)"
        >
          <b-icon icon="trash" aria-hidden="true" />
        </b-button>
      </template>
      <!-- pass on scoped slots from the multiselect component to the parent component -->
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </b-table>
    <div class="overflow-auto mt-2">
      <b-pagination
        v-model="tableControls.currentPage"
        :total-rows="allItems.length"
        :per-page="tableControls.perPage"
      />
      {{ $t('general.table.items-per-page') }}:
      <b-select
        id="per-page-select"
        class="w-auto"
        v-model="tableControls.perPage"
        :options="tableControls.perPageOptions"
        size="sm"
      />
    </div>

    <b-modal
      id="GoldflamTableDeleteModal"
      v-model="modalControl.delete.show"
      size="md"
      :title="$t('general.table.deleteTitle')"
      :ok-title="$t('general.delete')"
      ok-variant="danger"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      @ok="deleteItem"
    >
      <p>{{ $t('general.table.deleteMessage') }}</p>
      <b-alert v-if="deleteWarning !== null" show variant="warning" class="mt-4">
        {{ deleteWarning }}
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'GoldflamTable',
  components: {
    LoadingSpinner
  },
  mixins: [NotificationMixin],
  props: {
    tableFields: {
      required: true,
      type: Array
    },
    newItem: {
      required: true,
      type: Object
    },
    allItems: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    deleteFunction: {
      required: true,
      type: Function
    },
    editFunction: {
      required: true,
      type: Function
    },
    mergeFunction: {
      required: false,
      type: Function
    },
    disableCrudFunctions: {
      required: false,
      type: Function
    },
    busy: {
      required: false,
      type: Boolean,
      default: false
    },
    deleteModalPreventClose: {
      required: false,
      type: Boolean,
      default: false
    },
    deleteWarning: {
      required: false,
      type: String,
      default: null
    },
    sortBy: {
      required: false,
      type: String,
      default: null
    },
    stacked: {
      required: false,
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedItem: {},
      modalControl: {
        delete: {
          show: false
        }
      },
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      tableControls: {
        totalRows: 0,
        currentPage: 1,
        perPage: 20,
        perPageOptions: [20, 50, 100]
      }
    }
  },
  mounted() {
    this.tableControls.totalRows = this.allItems.length
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.tableControls.totalRows = filteredItems.length
    },
    openDeleteModal(item) {
      this.modalControl.delete.show = true
      this.selectedItem = item
    },
    deleteItem(bvModalEvt) {
      // Prevent modal from closing to be able to show a delete validation warning in the modal
      if (this.deleteModalPreventClose) {
        bvModalEvt.preventDefault()
      }
      // Trigger submit handler and pass modal ID as a reference as optional second parameter
      this.deleteFunction(this.selectedItem, 'GoldflamTableDeleteModal')
    },
    editItem(item) {
      this.editFunction(item)
    },
    crudFunctionsDisabled(item) {
      if (this.disableCrudFunctions) {
        return this.disableCrudFunctions(item)
      } else {
        return false
      }
    },
    formatDate(value) {
      return LocalDateTimeFormatter.formatLocaleDate(value)
    },
    formatTime(value) {
      return LocalDateTimeFormatter.toTime(value)
    },
    formatMoney(valueInCents) {
      let val = valueInCents / 100
      switch (this.$store.state.locale) {
        case 'de':
          return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val)
        case 'en':
        default:
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'EUR' }).format(val)
      }
    },
    formatRole(role) {
      return this.$t('user.roles.' + role.toLowerCase())
    },
    formatActivityStatus(status) {
      return this.$t('user.status.' + status.toLowerCase())
    },
    formatProjectStatus(status) {
      return this.$t('project.status.' + status.toLowerCase())
    },
    formatTicketStatus(status) {
      return this.$t('ticket.status.' + status.toLowerCase())
    },
    formatStringArray(stringArray) {
      return Array.from(stringArray).sort().join(', ')
    },
    formatFederalState(state) {
      return this.$t('federalState.' + state.toLowerCase())
    },
    formatPercentage(value) {
      return value + ' %'
    },
    formatDays(value) {
      let suffix = value === 1 ? this.$t('datetime.day.day') : this.$t('datetime.day.day-plural')
      return `${value} ${suffix}`
    },
    formatHours(value) {
      return value + ' ' + this.$t('datetime.hour.hour-short')
    },
    formatId(value) {
      return '#' + value
    },
    formatNameWithId(value) {
      return '#' + value.id + ' - ' + value.name
    },
    translateBoolean(value) {
      return this.$t('general.' + value)
    },
    formatLatitudeLongitude(value) {
      return value.toFixed(6)
    }
  }
}
</script>

<style scoped></style>
